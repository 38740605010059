@import './variables.scss';

html,
body,
#__next {
    width: 100%;
    height: auto;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.container {
    margin: 1em;
    max-width: $max-width;
}

.center {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.error {
    background: red;
    padding: 10px;
    color: white;
    margin-top: 1em;
}

.error:empty {
    display: none;
}

.success {
    background: green;
    padding: 10px;
    color: white;
    margin-top: 1em;
}

.success:empty {
    display: none;
}
