html,
body,
#__next {
    height: 100%;
}

.logoLogin {
    width: 183px;
    height: 68px;

    @include sm() {
        width: 121px;
        height: 45px;
    }
}

.reset-password-requirements-container {
    margin: 24px 0 24px 0;
}

.reset-password-line-one {
    display: flex;
    justify-content: space-between;

    @include sm() {
        flex-direction: column;
    }
}

.reset-password-line-two {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    @include sm() {
        flex-direction: column;
        margin-top: 0;
    }
}
