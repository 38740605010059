.fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000;
    color: white;
    z-index: 1000;
    font-size: 14px;
    margin-left: -13%;
    padding-left: 15%;
}

.nav {
    display: flex;
}

.nav-item {
    padding: 10px;
    display: flex;
    align-items: center;
}

.nav-item:hover {
    background-color: #302e2e;
}

.nav-item a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.nav-item a:hover {
    text-decoration: none;
}

.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #444;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    margin-left: -10px;
}

.dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #555;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.mr {
    margin-right: 5px;
}
