$breakpoints: (
    sm: 651px,
    md: 951px,
    xl: 1200px
);

/*
Mixin Media Query
Example Use:
.foo-bar {
    width: 50%;
    @include media($from: "sm", $until: 900px, $and:"orientation: landscape"){
        width: 100%;
    }
}
*/
@mixin media($from: false, $until: false, $and: false, $media-type: all) {
    $min-width: 0;
    $max-width: 0;
    $query: '';

    //FROM: this breakpoint (inclusive)
    @if $from {
        @if type-of($from) == number {
            $min-width: $from;
        } @else {
            $min-width: map-get($breakpoints, $from);
        }
    }

    //UNTIL: this breakpoint (exclusive)
    @if $until {
        @if type-of($until) == number {
            $max-width: $until - 1px;
        } @else {
            $max-width: map-get($breakpoints, $until) - 1px;
        }
    }

    @if $min-width != 0 {
        $query: '#{$query} and (min-width: #{$min-width})';
    }
    @if $max-width != 0 {
        $query: '#{$query} and (max-width: #{$max-width})';
    }
    @if $and {
        $query: '#{$query} and (#{$and})';
    }

    @if ($media-type == 'all' and $query != '') {
        $media-type: '';
        $query: str-slice(unquote($query), 6);
    }

    @media #{$media-type + $query} {
        @content;
    }
}

/**
Media Query - Small to Medium
*/

@mixin sm {
    @include media($until: 'sm') {
        @content;
    }
}

@mixin sm-md {
    @include media($from: 'sm', $until: 'md') {
        @content;
    }
}

/**
Media Query - Medium to Large
*/
@mixin md-lg {
    @include media($from: 'md', $until: 'lg') {
        @content;
    }
}

/**
Media Query - Large
*/
@mixin lg {
    @include media($from: 'lg') {
        @content;
    }
}
