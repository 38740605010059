@import './mixins.scss';
@import './utils.scss';
@import './login.page.scss';
@import './home.page.scss';
@import './admin.toolbar.component.scss';

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/KFOlCnqEu92Fr1MmSU5vAA.woff') format('woff');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/KFOmCnqEu92Fr1Me5g.woff') format('woff');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/KFOlCnqEu92Fr1MmEU9vAA.woff') format('woff');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/KFOlCnqEu92Fr1MmWUlvAA.woff') format('woff');
}

html,
body {
    padding: 0;
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        RobotoOxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

h4.null {
    color: #fff !important;
}

table.mcnBoxedTextBlock
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td {
    padding-left: 18px !important;
    padding-bottom: 15px !important;
    padding-right: 18px !important;
    padding-top: 15px !important;
    line-height: 1.5em !important;
}

table.mcnBoxedTextBlock > tbody > tr > td > table > tbody > tr > td {
    text-align: center;
}

.mcnBoxedTextBlock > tbody > tr > td > table > tbody > tr > td > table {
    background-color: #404040 !important;
}

.mcnBoxedTextBlock
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td
    > h4
    > span {
    color: #fff !important;
}

.mcnBoxedTextBlock
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td
    > div {
    color: #fff !important;
    text-align: center !important;
    font-weight: 400 !important;
}

table.mcnBoxedTextBlock
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td
    > span {
    color: #fff !important;
    font-weight: 400 !important;
}
